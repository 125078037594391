<template>
    <div class="preview_area show_sidebar" v-if="modelValue">
        <div class="global_setting">
            <button class="close_btn modal-window-close-button" @click="handleCloseSetting()"><i class="fas fa-long-arrow-alt-left"></i></button>
            <div class="tabs_content">
                <Form @submit="handleSubmit" @invalid-submit="handleFormInvalidSubmit" v-slot="{ errors }" class="checkout-setting-form">
                    <div class="step_wpr content_area">
                        <div class="info_bar">
                            <button type="button" class="video_btn setting-video-btn" @click="tutorial = true;"><img src="@/assets/images/play.svg"></button>
                            <!-- <button type="button" class="video_btn setting-video-btn" @click="tutorial = true;"><i class="fas fa-play"></i></button> -->
                            <div class="info">
                                Watch this to get set up!
                                <img src="@/assets/images/curve_dash_arrow.svg" alt="arrow">
                            </div>
                            <div class="step_bar2">
                                <ul>
                                    <li :class="{ active : step == 1 || step > 1 }">
                                        <span @click="step = 1">1</span>
                                        <h6>Company</h6>
                                    </li>
                                    <li :class="{ active : step == 2 || step > 2 }">
                                        <span @click="step = 2">2</span>
                                        <h6>Currency</h6>
                                    </li>
                                    <li :class="{ active : step == 3 || step > 3 }">
                                        <span @click="form.currency_term_agreed ? step = 3 : ''">3</span>
                                        <h6>Processors</h6>
                                    </li>
                                </ul>
                                <div class="total_bar"><span :style="`width:${(100 / 2) * (step - 1) }%`"></span></div>
                            </div>
                        </div>
                    </div>
                    <div class="content_wpr">
                        <company-setting v-if="step == 1" :company-handler="handleCompanyUpdate"></company-setting>
                        <div class="section_content w-100" v-if="step === 2">
                            <div class="color_container mt-1">
                                <div class="currency_wpr">
                                    <div class="setting_wpr mb-5">
                                        <div class="section_header mb-5">
                                            <h2>Currency:</h2>
                                        </div>
                                        <div class="form_grp mb-2 mt-2">
                                            <div class="group_item">
                                                <label class="input_label">What country will you be doing business in?</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.country }">
                                                    <Field autocomplete="off" name="country" v-model="form.business_country" :class="{ 'has-error': errors.country }" rules="required">
                                                        <multiselect
                                                            v-model="form.business_country"
                                                            :options="countries"
                                                            valueProp="code"
                                                            label="country"
                                                            :searchable="true"
                                                            autocomplete="nofill"
                                                            :disabled="((companySettings.products_count && companySettings.currency_term_agreed) || (companySettings.currency_term_agreed && companySettings.currency)) ? true : false"
                                                        ></multiselect>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="country" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp mb-2 mt-2">
                                            <div class="group_item">
                                                <label class="input_label">What currency will you be selling products in?</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.currency }">
                                                    <Field autocomplete="off" name="currency" v-model="form.currency" :class="{ 'has-error': errors.currency }" rules="required">
                                                        <multiselect
                                                            v-model="form.currency"
                                                            :options="currencies"
                                                            valueProp="code"
                                                            label="currency"
                                                            :searchable="true"
                                                            autocomplete="nofill"
                                                            :disabled="((companySettings.products_count && companySettings.currency_term_agreed) || (companySettings.currency_term_agreed && companySettings.currency)) ? true : false"
                                                        ></multiselect>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="currency" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="important_msg">
                                        <h2>Important!</h2>
                                        <p>Once you select your currency and connect your processor it can not be changed.<br><br> Your financial logs, your products, receipts, and your data will be in the currency shown below.</p>
                                        <ul>
                                            <li><span v-html="currency_symbol"></span>Symbol</li>
                                            <li><span>{{ form.currency ? form.currency : '¤' }}</span>Identifier</li>
                                        </ul>
                                        <div class="money_man">
                                            <img src="@/assets/images/moneyman.png" alt="">
                                        </div>
                                        <label for="agreed" class="checkbox">
                                            <input type="checkbox" id="agreed" v-model="form.currency_term_agreed" :true-value="1" :false-value="0" hidden :disabled="(companySettings.products_count && companySettings.currency_term_agreed) || (companySettings.currency_term_agreed && companySettings.currency)">
                                            <span><i class="fas fa-check"></i></span>
                                            <p class="m-0">I agree this can not be changed.</p>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="action_wpr mt-5" v-show="form.currency_term_agreed">
                                <button type="submit" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="loader"></i> {{ loader ? 'Saving' : 'Next' }}</button>
                            </div>
                        </div>
                        <div class="section_content w-100" v-if="step === 3">
                            <div class="color_container mt-1">
                                <div class="section_header mb-5">
                                    <h2>Processors</h2>
                                </div>
                                <div class="section_wpr">
                                    <ul>
                                        <li v-for="(processor, p) of processors" :key="p">
                                            <div class="stripe_card">
                                                {{ processor.name }}
                                                <span>Stripe</span>
                                                <button type="button" class="delete_btn" @click="handleDeleteProcessor(processor.id)">
                                                    <i class="fas fa-trash-alt danger"></i>
                                                </button>
                                            </div>
                                        </li>
                                        <li @click="handleAddProcessor()">
                                            <div class="stripe_card">
                                                <i class="fas fa-plus"></i> Add Account
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
        <video-tutorial v-model="tutorial" :tutorial-video="tutorialVideo"></video-tutorial>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapGetters, mapActions } from 'vuex'

    const CompanySetting = defineAsyncComponent(() => import('@/pages/checkout/components/setting/CompanySetting'))
    const VideoTutorial = defineAsyncComponent(() => import('@/components/VideoTutorial'))

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'

    export default {
        name: 'Checkout Setting',

        data () {
            return {
                step: 1,
                env: {},
                tutorial: false,
                tutorialVideo: 'https://www.loom.com/embed/02783ea463cc4164aa92e8ceb541eaa1?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true',
                form: {
                    id: 0,
                    business_country: '',
                    currency: '',
                    currency_term_agreed: 0,
                },
                currency_symbol: '&#164;',
            }
        },

        components:{
            Form,
            Field,
            ErrorMessage,
            CompanySetting,
            VideoTutorial
        },

        props: {
            modelValue: Boolean,
        },

        emits: ['update:modelValue'],

        watch:{
            modelValue (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            'form.business_country' (country) {
                const vm = this;

                if (country == 'US' || country == 'CA' || country == 'AU') {
                    this.form.currency = 'USD';
                } else if(country == 'GB') {
                    this.form.currency = 'GBP';
                } else if(country == 'AE') {
                    this.form.currency = 'AED';
                } else if(country == 'IN') {
                    this.form.currency = 'INR';
                } else if(country == 'CN') {
                    this.form.currency = 'CNY';
                } else if(country == 'CH') {
                    this.form.currency = 'CHF';
                }
            },

            'form.currency' (cur){
                const vm = this;

                if (cur == 'USD' || cur == 'AUD' || cur == 'CAD') {
                    vm.currency_symbol = '&#36;';
                } else if(cur == 'EUR') {
                    vm.currency_symbol = '&#128;';
                } else if(cur == 'GBP') {
                    vm.currency_symbol = '&#163;';
                } else if(cur == 'INR') {
                    vm.currency_symbol = '&#x20B9';
                } else if(cur == 'AED') {
                    vm.currency_symbol = '&#x62f;&#x2e;&#x625;';
                } else if(cur == 'CHF') {
                    vm.currency_symbol = '&#8355;';
                } else if(cur == 'CNY' || cur == 'JPY') {
                    vm.currency_symbol = '&#165';
                } else if(cur == 'MYR') {
                    vm.currency_symbol = 'RM';
                }
            },

            companySettings (setting) {
                const vm = this;

                vm.form.id                    = setting.id;
                vm.form.currency              = setting.currency;
                vm.form.business_country      = setting.business_country;
                vm.form.currency_term_agreed  = setting.currency_term_agreed;
            },
        },

        computed: {
            ...mapState({
                user: state => state.authModule.user,
                membership: state => state.authModule.membership,
                whiteLabel: state => state.authModule.whiteLabel,
                processors: state => state.checkoutModule.processors,
                countries: state => state.commonModule.countries,
                currencies: state => state.commonModule.currencies,
                companySettings: state => state.checkoutModule.companySettings,
                loader: state => state.checkoutModule.updateCompanySettingLoader,
            }),

            ...mapGetters({
                replaceVariables: 'commonModule/REPLACE_VARIABLES',
                generateSignature: 'commonModule/GENERATE_SIGNATURE',
            }),
        },

        mounted () {
            const vm = this;

            vm.env = process.env;
        },

        methods: {
            ...mapActions({
                createProcessor: 'checkoutModule/createProcessor',
                deleteProcessor: 'checkoutModule/deleteProcessor',
                getProcessors: 'checkoutModule/getProcessors',
                updateCompanyCurrencySettings: 'checkoutModule/updateCompanyCurrencySettings',
                refreshAuth: 'authModule/refreshAuth'
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handleAddProcessor () {
                const vm = this;
                const callback  = vm.env.VUE_APP_STRIPE_CHECKOUT_REDIRECT;
                const clientId  = vm.env.VUE_APP_STRIPE_CHECKOUT_CLIENT_ID;
                const state     = btoa(JSON.stringify({user_id: vm.user.id, email: vm.user.email, access_token: vm.user.access_token }));

                window.location.href = 'https://connect.stripe.com/oauth/authorize?response_type=code&client_id='+clientId+'&scope=read_write&redirect_uri='+callback+'&stripe_landing=login&state='+state;
            },

            handleDeleteProcessor (id) {
                const vm = this;

                const options = Helper.swalConfirmOptions('Are you sure?', `This action is permanent. You will not be able to recover this processor. Please type DELETE to remove this processor.`, 'Delete');

                options.input = 'text';
                options.inputAttributes = { placeholder: 'Enter DELETE' };
                options.inputValidator  = (input) => {
                                              if (/^[A-Z]/.test(input) == false) {
                                                  return 'Please enter all capital letters';
                                              }

                                              if (input !== 'DELETE') {
                                                  return `Please type DELETE to delete processor`;
                                              }
                                          }

                options.preConfirm = function () {
                                        return vm.deleteProcessor(id).then((result) => {
                                            vm.getProcessors();
                                        });
                                    };

                Swal.fire(options);
            },

            handleCompanyUpdate () {
                const vm = this;

                vm.step = 2;
            },

            handleSubmit (form, { setFieldError }) {
                const vm = this;

                vm.form.setFieldError = setFieldError;

                vm.updateCompanyCurrencySettings(vm.form).then((result) => {
                    if (result) {
                        vm.step++;

                        if (vm.step > 1 && !vm.user.checkout_currency_term_agreed) {
                            vm.refreshAuth();
                        }
                    }
                });
            },

            handleCloseSetting () {
                const vm = this;

                if (vm.user.id && (!vm.user.checkout_currency_term_agreed || !vm.user.has_checkout_processor)) {
                    vm.$router.push({name: 'ContactIndex'});
                } else {
                    vm.closeModal();
                }
            }
        }
    }
</script>

<style scoped>
    .form_grp .field_wpr {
        display: flex;
    }

    .form_grp .field_wpr .basic_slug {
        height: 50px;
        padding: 0 15px;
        font-size: 13px;
        line-height: 17px;
        font-weight: 300;
        color: #999;
        display: flex;
        align-items: center;
        border-left: 1px solid #e6e6e6;
        border-right: 1px solid #e6e6e6;
    }

    p.note {
        padding: 15px 20px;
        background: rgba(47, 126, 237, 0.05);
        border: 1px solid rgba(47, 126, 237, 0.1);
        border-radius: 5px;
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        color: #121525;
    }

    .checkout-setting-form {
        display: flex;
        flex-direction: column;
        height: inherit;
        max-width: 950px;
        margin: 0 auto;
    }

    .checkout-setting-form .color_container {
        background: #fff;
        border: 1px solid #f5f5f5;
    }

    .global_setting .preview_content {
        position: relative;
        background: #fff;
        transition: all 0.3s ease-in-out;
        z-index: 4;
        margin-right: -40%;
    }

    .preview_content.show {
        margin-right: 0;
    }

    .preview_content .close_btn {
        position: absolute;
        left: -35px;
        top: 5px;
        font-size: 14px;
    }

    .preview_btn {
        padding: 10px 20px;
        border-radius: 3px;
        background: #32373b;
        color: #fff;
        font-size: 12px;
        line-height: 15px;
        font-weight: 300;
        position: fixed;
        right: -30px;
        top: 100px;
        text-transform: uppercase;
        letter-spacing: 1px;
        transform: rotate(-90deg);
        cursor: pointer;
    }

    .preview_content .cell {
        width: 405px;
        padding: 60px 15px 80px;
        border-radius: 40px;
        box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.2);
        position: relative;
        margin: 30px auto;
    }

    .preview_content .cell:after,
    .preview_content .cell:before {
        position: absolute;
        content: "";
        width: 50px;
        background: #f2f2f2;
        left: 50%;
        transform: translateX(-50%);
    }

    .preview_content .cell:before {
        height: 7px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        top: 25px;
    }

    .preview_content .cell:after {
        height: 50px;
        border: 1px solid #d9d9d9;
        border-radius: 50%;
        bottom: 15px;
    }

    .preview_content .cell .content_area {
        height: 610px;
        padding: 0;
        border: 1px solid #e7e7e7;
        background: #fafafb;
        position: relative;
        display: flex;
        flex-direction: column;
    }

    .cell .msg_preview {
        padding: 20px;
        background: #fff;
        border: 1px solid #eaeaea;
        margin: 15px;
        position: relative;
        border-radius: 10px;
    }

    .cell .sms_preview {
        padding: 15px 20px;
        background: #fff;
        border: 1px solid #eaeaea;
        margin: 15px;
        position: relative;
        border-radius: 10px;
        border-radius: 10px;
    }

    .cell .sms_preview p {
        font-size: 13px;
        line-height: 20px;
        font-weight: 400;
        color: #5a5a5a;
    }

    .cell .msg_preview:after,
    .cell .sms_preview:after {
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 13px;
        left: calc(50% - 10px);
        top: 100%;
    }

    .cell .sms_preview:after {
        right: 30px;
        left: auto;
    }

    .cell .msg_preview h2 {
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 15px;
        font-weight: 500;
        color: #121525;
    }

    .cell .msg_preview :deep(p) {
        font-size: 11px !important;
        line-height: 15px;
        margin-bottom: 10px;
        font-weight: 400;
        color: #5a5a5a;
    }

    .cell .msg_preview p span {
        font-size: 11px;
        line-height: 16px;
        padding: 0;
        font-weight: 500;
        color: #121525;
    }

    .cell .msgFooter :deep(p) {
        font-size: 11px;
        line-height: 17px;
    }

    .cell .msgFooter :deep(p a) {
        text-decoration: none;
    }

    .sender_info {
        display: flex;
        padding-bottom: 5px;
        margin-bottom: 40px;
        /* border-bottom: 1px solid #eaeaea; */
    }

    .sender_info img {
        width: 35px;
        height: 35px;
        margin-right: 10px;
    }

    .sender_info .info {
        margin: 0;
    }

    .sender_info .info h5 {
        font-size: 13px;
        line-height: 15px;
        font-weight: 500;
        color: #2f7eed;
        margin-bottom: 3px;
    }

    .sender_info .info h6 {
        font-size: 11px;
        line-height: 13px;
        font-weight: 500;
        color: #5a5a5a;
        margin-bottom: 3px;
    }

    .sender_info .info h6 span {
        color: #121525;
        font-weight: 600;
        padding-right: 5px;
    }

    .mail_types {
        margin: 20px 0;
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .mail_types li {
        background: #fff;
        border-radius: 8px;
        box-shadow: 0 1px 10px rgba(0, 0, 0, 0.05);
        overflow: hidden;
    }

    .mail_types li h4 {
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        color: #121525;
        padding: 20px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: move;
    }

    .mail_types li h4 i {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid #e9e9e9;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        cursor: pointer;
        color: #7e7e7e;
        transition: all 0.3s ease-in-out;
    }

    .mail_types li.show h4 i {
        transform: rotate(-180deg);
    }

    .mail_types li .accordion_area {
        max-height: 0;
        padding: 0 30px;
        height: 100%;
        border-top: 1px solid #e9e9e9;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
    }

    .mail_types li.show .accordion_area {
        max-height: 1000vh;
        height: 100%;
        overflow-y: scroll;
    }

    .mail_types li.show .accordion_area::-webkit-scrollbar {
        display: none;
    }

    p.note {
        padding: 10px 20px;
        background: rgba(47, 126, 237, 0.05);
        border: 1px solid rgba(47, 126, 237, 0.1);
        border-radius: 5px;
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        color: #121525;
    }

    p.note :deep(p) {
        margin: 10px 0;
    }

    .stripe_card {
        border: 1px solid #E9E9E9;
        padding: 30px 10px;
        border-radius: 9px;
        background: #fff;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
        font-size: 13px;
        line-height: 17px;
        color: #5A5A5A;
        font-weight: 400;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 100%;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .stripe_card span {
        padding: 3px 10px;
        border-radius: 10px;
        background: #2f7eed;
        color: #fff;
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin-top: 15px;
    }

    .stripe_card .delete_btn {
        position: absolute;
        right: -30px;
        top: 10px;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }

    .stripe_card .delete_btn i {
        font-size: 13px;
    }

    .stripe_card:hover {
        box-shadow: 0px 0px 44px rgb(0, 0, 0, 0.13);
    }

    .stripe_card:hover .delete_btn {
        right: 10px;
    }

    .stripe_card i {
        font-size: 16px;
        color: #2f7eed;
        margin-bottom: 10px;
    }

    .setting-video-btn {
        position: absolute;
        top: 12px;
        left: 35px;
        z-index: 6;
        border: 0;
    }

    .global_setting .tabs_content .content_wpr {
        width: 100%;
        margin: 0 auto;
    }

    .global_setting .tabs_content :deep(form>.action_wpr) {
        position: static;
        border-top: 0;
        padding: 10px 20px;
    }

    .info_bar {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 10px;
        position: relative;
        padding: 0 40px;
    }
    .info_bar .setting-video-btn{
        top: 20px;
        left: 0;
        margin: 0;
    }
    .info_bar .info{
        position:absolute;
        left: 3px;
        top: 75%;
        font-family: 'Architects Daughter', cursive;
        font-size: 14px;
        line-height: 20px;
        color: #121525;
        width: 165px;
    }
    .info_bar .info img{
        position: absolute;
        bottom: 110%;
        left: 30px;
        width: 50px;
        transform: scaleX(-1);
    }

    .step_bar2 {
        max-width: 400px;
        width: 100%;
        position: relative;
        padding: 20px 30px 10px 30px;
    }

    .step_bar2 ul {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-bottom: 25px;
        position: relative;
        z-index: 1;
    }

    .step_bar2 ul li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .step_bar2 ul li h6 {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #121525;
        white-space: nowrap;
        text-align: center;
    }

    .step_bar2 ul li:first-child h6 {
        left: 0;
        transform: none;
        text-align: left;
    }

    .step_bar2 ul li:last-child h6 {
        right: 0;
        left: auto;
        transform: none;
        text-align: right;
    }

    .step_bar2 ul li span {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background: #fff;
        border: 3px solid #e5e5e5;
        font-size: 12px;
        font-weight: 500;
        color: #2c3e50;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }

    .step_bar2 .total_bar {
        position: absolute;
        content: '';
        top: 32px;
        left: 30px;
        right: 30px;
        height: 2px;
        background: #d9d9d9;
    }

    .step_bar2 .total_bar span {
        background: #a1c8ff;
        position: absolute;
        left: 0;
        height: 100%;
        transition: all 0.3s ease-in-out;
    }

    .step_bar2 ul li.active span {
        background: #2f7eed;
        color: #fff;
        border-color: #a1c8ff;
    }

    .currency_wpr {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 100px;
    }

    .currency_wpr .setting_wpr {
        flex: 1 1 auto;
    }

    .currency_wpr .important_msg {
        max-width: 300px;
        width: 100%;
        /* border: 1px solid #e9e9e9; */
        background: #f6f9ff;
        border-radius: 5px;
        padding: 20px;
        margin: 15px 0;
    }

    .currency_wpr .important_msg h2 {
        font-size: 20px;
        line-height: 26px;
        font-weight: 500;
        color: #2f7eed;
        text-align: center;
        margin-bottom: 15px;
    }
    .currency_wpr .important_msg p{
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        color: #5a5a5a;
        text-align: center;
        margin-bottom: 20px;
    }

    .currency_wpr .important_msg ul {
        display: flex;
        justify-content: center;
        gap: 15px;
        margin: 25px 0 30px 0;
    }

    .currency_wpr .important_msg ul li {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        font-size: 11px;
        line-height: 15px;
        font-weight: 500;
        color: #121525;
    }

    .currency_wpr .important_msg ul li span {
        width: 60px;
        height: 55px;
        background: #2f7eed;
        color: #fff;
        font-size: 20px;
        line-height: 26px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .currency_wpr .important_msg .money_man{
        display: flex;
        justify-content: center;
    }
    .currency_wpr .important_msg .money_man img{
        max-height: 160px;
        width: auto;
    }
    .currency_wpr .important_msg label.checkbox{
        margin: 15px auto;
        justify-content: center;
        align-items: center;
    }
    .global_setting .tabs_content .content_area{
        padding: 20px 15px 0 15px;
    }
    .global_setting .tabs_content :deep(.section_content){
        padding: 20px 15px;
    }
    @media(max-width: 1250px){
        .info_bar .info{
            left: 0;
            top: 60px;
            font-size: 13px;
            line-height: 18px;
        }
        .info_bar .info img{
            transform: scaleX(-1);
            left: 35px;
            width: 40px;
        }
    }
    @media(max-width: 1199px){
        .global_setting .tabs_content :deep(form>.action_wpr) {
            padding: 10px 15px !important;
        }
    }
    @media(max-width: 767px){
        .global_setting :deep(.action_wpr .btn){
            height: 40px;
            padding: 0 30px;
            font-size: 13px;
        }
        .info_bar .info{
            top: 80px;
            left: 60px;
        }
        .info_bar .info img{
            transform: rotate(-90deg);
            bottom: 10px;
            left: -50px;
            width: 45px;
        }
        .currency_wpr{
            flex-direction: column;
            gap: 0;
            padding-top: 15px;
        }
        .section_wpr ul li{
            width: 33.333%;
        }
    }
    @media(max-width: 499px){
        .global_setting .tabs_content :deep(.section_content){
            padding-top: 0;
        }
        .section_wpr ul li{
            width: 50%;
        }
    }
</style>